import loadable from '@loadable/component';

export const pages = {
    Home: loadable(() => import('./Home')),
    Resume: loadable(() => import('./Resume')),
    Industry: loadable(() => import('./Industry')),
    ResumeBasic: loadable(() => import('./Resume/Basic')),
    ResumeExpect: loadable(() => import('./Resume/Expect')),
    ResumeEducation: loadable(() => import('./Resume/Education')),
    ResumeWork: loadable(() => import('./Resume/Work')),
    ResumeLanguage: loadable(() => import('./Resume/Language')),
    ResumeProject: loadable(() => import('./Resume/Project')),
    ResumeTraining: loadable(() => import('./Resume/Training')),
    ResumeCertificate: loadable(() => import('./Resume/Certificate')),
    ResumeSelfRemark: loadable(() => import('./Resume/SelfRemark')),
    ResumeSimple: loadable(() => import('./Resume/Simple')),
    Fat: loadable(() => import('./Fat')),
    LinkToH5: loadable(() => import('./LinkToH5'))
};
